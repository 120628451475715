import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { CouponID, CouponTemplateID } from '@playq/services-bookkeeper';

import { services2 } from '/api/services2';
import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';

export type CouponPromotionPayload = {
  id: CouponID | CouponTemplateID;
  env: Environment;
  demote?: boolean;
};

export const useCouponPromotion = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CouponPromotionPayload>
) =>
  useEitherMutation(({ id, env, demote }) => {
    const method = demote ? 'demote' : 'promote';
    if (id instanceof CouponID) {
      return services2.massCouponsService[method](id, env);
    }
    return services2.targetedCouponsService[method](id, env);
  }, options);
