import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppEntityContent, AppEntityContentResponse } from '@playq/octopus2-apps';
import { GenericFailure } from '@playq/services-shared';
import {
  Event,
  EventFieldCustom,
  EventHelpers,
  EventLocalSystem,
  Events,
  EventsSerialized,
  EventSystem,
  PlayerProfile,
  SystemEvent,
} from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';

import { UpsertEventsMapperPayload } from './types';
import { eventsMapperRetrieveKeys, useRetrieveSystemEvents } from './useRetrieveSystemEvents';

const emptyArray: [] = [];

const makeSystemEventCopy = (systemEvent: SystemEvent) => {
  if (systemEvent instanceof EventSystem) {
    return new EventSystem(systemEvent.serialize());
  }
  if (systemEvent instanceof EventLocalSystem) {
    return new EventLocalSystem(systemEvent.serialize());
  }
};

export const useEventsMapperRetrieve = (
  version?: number,
  options?: UseQueryOptions<AppEntityContentResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const { events: systemEvents, isLoading: isSystemEventsLoading } = useRetrieveSystemEvents({
    staleTime: Infinity,
  });

  const { mutate, setQueryData, ...res } = useEitherQuery(
    eventsMapperRetrieveKeys.concat(appID, version),
    () => {
      if (appID) {
        return services2.appEventsMapperService.retrieve(appID, version);
      }
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );

  const events = useMemo(() => {
    if (res.data !== undefined) {
      try {
        const eventContent = JSON.parse(res.data.entity.content) as EventsSerialized;

        const newEvent = new Events();
        newEvent.events = eventContent.events.map((e) => EventHelpers.deserialize(e));
        newEvent.player = new PlayerProfile(eventContent.player);
        newEvent.updatedAt = new Date(eventContent.updatedAt);
        newEvent.version = eventContent.version;
        return newEvent;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  }, [res.data]);

  const eventsWithSystemEvents = useMemo(() => {
    if (isSystemEventsLoading) {
      return emptyArray;
    }
    if (events?.events === undefined) {
      return systemEvents;
    }
    const mergedEvents: Event[] = [];
    systemEvents.forEach((systemEvent) => {
      const userEvent = events.events.find((e) => e.id === systemEvent.id);
      const systemEventCopy = makeSystemEventCopy(systemEvent);
      if (!systemEventCopy) {
        return;
      }
      if (userEvent && userEvent.fields.length !== systemEvent.fields.length) {
        systemEventCopy.fields = systemEventCopy.fields.concat(
          ...(userEvent.fields.filter(
            (eventField) => !systemEventCopy.fields.some((systemField) => systemField.target === eventField.target)
          ) as EventFieldCustom[])
        );
      }
      mergedEvents.push(systemEventCopy);
    });
    mergedEvents.push(
      ...events.events.filter((event) => !systemEvents.some((systemEvent) => systemEvent.id === event.id))
    );
    return mergedEvents;
  }, [events?.events, systemEvents, isSystemEventsLoading]);

  const mutateUpdatedEventsMapper = useCallback(
    (payload: UpsertEventsMapperPayload) => {
      if (payload.upload.version !== undefined) {
        const response = new AppEntityContentResponse();
        response.lastVersion = payload.upload.version;
        response.entity = new AppEntityContent();
        response.entity.content = payload.upload.content;
        response.entity.version = payload.upload.version;
        setQueryData(eventsMapperRetrieveKeys.concat(appID, payload.upload.version), response);
        if (payload.upload.version === response.lastVersion) {
          setQueryData(eventsMapperRetrieveKeys.concat(appID, undefined), response);
        }
      }
    },
    [appID, setQueryData]
  );

  return {
    event: events,
    events: eventsWithSystemEvents,
    playerProfile: events?.player,
    lastVersion: res.data?.lastVersion,
    version: res.data?.entity.version,
    mutateUpdatedEventsMapper,
    mutate,
    setQueryData,
    ...res,
  };
};
