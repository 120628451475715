import { useMemo } from 'react';

import { CouponsFilterField } from '@playq/octopus2-economy';

import { QueryHelpers } from '/helpers';

import { useCouponsQuery } from './useCouponsQuery';
import { UseCouponPromotionsQueryParams } from './types';

export const useCouponPromotionsQuery = ({
  couponType,
  id,
  componentType,
  listQuery,
  options,
}: UseCouponPromotionsQueryParams) => {
  const query = useMemo(
    () => QueryHelpers.getValueByComponentType(componentType, id, listQuery, CouponsFilterField.Id),
    [id, listQuery, componentType]
  );

  const singleEntityQuery = useMemo(
    () =>
      componentType === 'editor'
        ? undefined
        : QueryHelpers.getValueByComponentType('editor', id, listQuery, CouponsFilterField.Id),
    [id, listQuery, componentType]
  );

  return useCouponsQuery(couponType, query.iterator, [], query.filterBy, {
    enablePrefetch: false,
    ...options,
    enabled: options?.enabled !== false && componentType === 'editor',
    singleEntityQuery,
  });
};
