import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppGameEventResponse, GameEventWithVersion } from '@playq/octopus2-liveops';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { gameEventRetrieveBatchQueryKey } from './constants';

export const useGameEventsBatchRetrieve = (
  options?: UseMutationOptions<AppGameEventResponse[], GenericFailure | Error, GameEventWithVersion[]>
) =>
  useEitherMutation((ids) => services2.appGameEventsService.retrieveBatch(ids), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: gameEventRetrieveBatchQueryKey,
    ...options,
  });
