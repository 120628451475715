import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const gameEventsService = services2.appGameEventsService;
export const gameEventsServiceName = services2.appGameEventsService.getClassName();

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const GAME_EVENT_PROMOTION_HOOK_NAME = 'onGameEventPromote';
export const GAME_EVENT_DEMOTION_HOOK_NAME = 'onGameEventDemote';
export const GAME_EVENT_SAVE_HOOK_NAME = 'onGameEventSave';

export const gameEventsQueryKeys: QueryKey = ['game-events', 'service', 'query'];
export const gameEventRetrieveQueryKey: QueryKey = ['game-event', 'retrieve'];
export const gameEventRetrieveBatchQueryKey: QueryKey = ['game-event', 'retrieve', 'retrieveBatch'];

export const createGameEventMutationKey: MutationKey = ['game-event', 'mutation', 'create'];
export const updateGameEventMutationKey: MutationKey = ['game-event', 'mutation', 'update'];
export const deleteGameEventMutationKey: MutationKey = ['game-event', 'mutation', 'delete'];
